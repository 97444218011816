import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Lob, Period } from '@cmi/shared/models';
import { KpiNarrative, SummaryDashboard, TimeSeriesDashboard } from '@cmi/store/dashboard';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class DashboardHttpService {
  private baseUrl = `${environment.cmiApiUri}`;

  constructor(private httpClient: HttpClient) {}

  getDashboardPeriods(): Observable<Period[]> {
    const url = `${this.baseUrl}/api/dashboard/periods`;

    return this.httpClient.get<Period[]>(url, { responseType: 'json' });
  }

  getDashboardLobs(): Observable<Lob[]> {
    const url = `${this.baseUrl}/api/dashboard/lobs`;

    return this.httpClient.get<Lob[]>(url, { responseType: 'json' });
  }

  getDashboardExecutiveSummary(period: string): Observable<string> {
    const url = `${this.baseUrl}/api/dashboard/executivesummary/${period}`;

    return this.httpClient.get<string>(url, { responseType: 'json' });
  }

  getDashboardYonYData(period: string): Observable<SummaryDashboard[]> {
    const url = `${this.baseUrl}/api/dashboard/yony/${period}`;

    return this.httpClient.get<SummaryDashboard[]>(url, { responseType: 'json' });
  }

  getDashboardQonQData(period: string): Observable<SummaryDashboard[]> {
    const url = `${this.baseUrl}/api/dashboard/qonq/${period}`;

    return this.httpClient.get<SummaryDashboard[]>(url, { responseType: 'json' });
  }

  getDashboardTimeSeriesYonYData(): Observable<TimeSeriesDashboard[]> {
    const url = `${this.baseUrl}/api/dashboard/timeseriesyony`;

    return this.httpClient.get<TimeSeriesDashboard[]>(url, { responseType: 'json' });
  }

  getDashboardTimeSeriesQonQData(): Observable<TimeSeriesDashboard[]> {
    const url = `${this.baseUrl}/api/dashboard/timeseriesqonq`;

    return this.httpClient.get<TimeSeriesDashboard[]>(url, { responseType: 'json' });
  }

  getDashboardYonYKpiNarrative(kpi: string, subLobId: string): Observable<KpiNarrative[]> {
    const url = `${this.baseUrl}/api/dashboard/yonykpinarrative/${kpi}/${subLobId}`;

    return this.httpClient.get<KpiNarrative[]>(url, { responseType: 'json' });
  }

  getDashboardQonQKpiNarrative(kpi: string, subLobId: string): Observable<KpiNarrative[]> {
    const url = `${this.baseUrl}/api/dashboard/qonqkpinarrative/${kpi}/${subLobId}`;

    return this.httpClient.get<KpiNarrative[]>(url, { responseType: 'json' });
  }
}
