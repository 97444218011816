import { createAction, props } from '@ngrx/store';

const PREFIX = '[App]';

export const appInitialise = createAction(`${PREFIX} Initialise`);
export const appUpdateCompanyLogo = createAction(
  `${PREFIX} Update Company Logo`,
  props<{ companyLogo: string }>()
);
export const appUpdateNavLocation = createAction(
  `${PREFIX} Update Nav Location`,
  props<{ navLocation: string; navRoot: boolean }>()
);
export const appHeight = createAction(`${PREFIX} Height`, props<{ height: number }>());
