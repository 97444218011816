import { UserState, userUpdate } from '@cmi/store/user';
import { createReducer, on } from '@ngrx/store';

export const userInitialState = {
  permissions: [] as string[]
} as UserState;

export const userReducer = createReducer(
  userInitialState,
  on(
    userUpdate,
    (_state, prop) => ({ ...prop.userProfile, permissions: prop.permissions }) as UserState
  )
);
