import { modalOpen, Modal, ModalState, modalClosing, modalClosed } from '@cmi/store/modal';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export interface ModalEntityState extends EntityState<Modal> {}

const modalAdapter = createEntityAdapter<Modal>({ selectId: (state) => state.key });

export const modalInitialState = modalAdapter.getInitialState();

export const modalReducer = createReducer(
  modalInitialState,
  on(modalOpen, (state, prop) =>
    modalAdapter.upsertOne({ key: prop.key, modalState: ModalState.Open }, state)
  ),
  on(modalClosing, (state, prop) =>
    modalAdapter.upsertOne({ key: prop.key, modalState: ModalState.Closing }, state)
  ),
  on(modalClosed, (state, prop) => modalAdapter.removeOne(prop.key, state))
);

export const { selectEntities } = modalAdapter.getSelectors();
