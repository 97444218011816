import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  handleError(error: Error) {
    //
    // NOTE: The error object could be handled here and passed onto a monitoring system (App Insights or Google Analytics etc.)
    //
    if (environment.loggingEnabled) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
