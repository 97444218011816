import { RadioComponent, RadioFormConfig } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LOCAL_GLOBAL_INTERNATIONAL, LOCAL_US } from '@cmi/store/app';

@Component({
  selector: 'app-local-radios',
  standalone: true,
  imports: [CommonModule, RadioComponent],
  templateUrl: './local-radios.component.html',
  styleUrl: './local-radios.component.scss'
})
export class LocalRadiosComponent implements OnInit {
  @Input() prefix!: string;
  @Input() local!: string;
  @Input() autoFocus = false;
  @Output() localChange = new EventEmitter<string>();

  localGlobalInternConfig = {} as RadioFormConfig;
  localUSConfig = {} as RadioFormConfig;

  ngOnInit() {
    this.localGlobalInternConfig = {
      id: `${this.prefix}-global-international`,
      name: `${this.prefix}-radio`,
      label: LOCAL_GLOBAL_INTERNATIONAL,
      selectedValue: LOCAL_GLOBAL_INTERNATIONAL,
      ariaLabel: LOCAL_GLOBAL_INTERNATIONAL,
      autoFocus: this.autoFocus
    } as RadioFormConfig;
    this.localUSConfig = {
      id: `${this.prefix}-us`,
      name: `${this.prefix}-radio`,
      label: LOCAL_US,
      selectedValue: LOCAL_US,
      ariaLabel: LOCAL_US
    } as RadioFormConfig;
  }

  valueChange(selectedLocal: string) {
    this.localChange.emit(selectedLocal);
  }
}
