import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AppHttpService {
  private baseUrl = `${environment.cmiApiUri}`;

  constructor(private httpClient: HttpClient) {}

  getCompanyLogo(): Observable<Blob> {
    const url = `${this.baseUrl}/api/file/logo`;

    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
