export const APP_FEATURE_KEY = 'app';

export const APP_INITIALISE_BUSY_INDICATOR = 'app-initialise-busy-indicator';
export const APP_BUSY_INDICATOR = 'app-busy-indicator';
export const APP_NARRATIVE_BUSY_INDICATOR = 'app-narrative-busy-indicator';

export const APP_HEADER_HEIGHT = 95;
export const APP_FOOTER_HEIGHT = 56;

export const LOCAL_US = 'US';
export const LOCAL_GLOBAL_INTERNATIONAL = 'Global/International';

// CMI top header navigation items
export const NAV_QUARTERS = 'Quarters';
export const NAV_COMPANIES = 'Companies';
export const NAV_DASHBOARDS = 'Dashboards';
