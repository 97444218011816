<re-modal
  [target]="DASHBOARD_FILTER_LOBS_MODAL"
  title="Filter LOB's"
  [buttonTemplate]="footerButtons">
  <re-busy [busy]="busy$ | async" [message]="message"></re-busy>
  <div>
    <div>
      <app-local-radios prefix="filter" [autoFocus]="true" [(local)]="local"></app-local-radios>
    </div>
    <hr />
    <div class="re-lobs" [formGroup]="formGroup">
      <ng-container formArrayName="lobs">
        <ng-container *ngFor="let lob of lobs().controls; let i = index" [formGroupName]="i">
          <div *ngIf="lobConfigs[i].local === local">
            <h5>{{ lobConfigs[i].lobName }}</h5>
            <div formArrayName="sublobs">
              <ng-container
                *ngFor="let lob of sublobs(lob).controls; let j = index"
                [formGroupName]="j">
                <re-checkbox
                  formControlName="sublob"
                  [formConfig]="lobConfigs[i].subLobConfigs[j].config"></re-checkbox>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <ng-template #footerButtons>
    <re-button class="re-tertiary" (clicked)="onReset()">Reset</re-button>
    <re-button class="re-secondary" (clicked)="onCancel()">Cancel</re-button>
    <re-button class="re-primary" [disabled]="!changed" (clicked)="onSave()">Save</re-button>
  </ng-template>
</re-modal>
