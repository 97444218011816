import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FileHttpService } from '@cmi/shared/services';
import { APP_BUSY_INDICATOR, appUpdateNavLocation, NAV_DASHBOARDS } from '@cmi/store/app';
import {
  dashboardInitialise,
  dashboardSelectedPeriod,
  dashboardUpdateGrid,
  DashboardHttpService,
  dashboardInitialiseComplete,
  LocalStorageService,
  dashboardSelectedPeriodFrom,
  dashboardDownloadFile
} from '@cmi/store/dashboard';
import { processBusy, processComplete, processError } from '@cmi/store/process';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import FileSaver from 'file-saver';
import {
  asyncScheduler,
  catchError,
  concat,
  forkJoin,
  mergeMap,
  observeOn,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private dashboardHttpService: DashboardHttpService,
    private fileHttpService: FileHttpService,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute
  ) {}

  dashboardInitialise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardInitialise),
      observeOn(asyncScheduler),
      withLatestFrom(this.route.queryParams),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, routeQueryParams]) =>
        concat(
          of(
            processBusy({ key: APP_BUSY_INDICATOR }),
            appUpdateNavLocation({ navLocation: NAV_DASHBOARDS, navRoot: true })
          ),
          forkJoin([
            this.localStorageService.selectedLobs(),
            this.dashboardHttpService.getDashboardPeriods(),
            this.dashboardHttpService.getDashboardLobs()
          ]).pipe(
            switchMap(([selectedLobs, periods, lobs]) => {
              const selectedDashboard = routeQueryParams['dashboardType'];
              const selectedPeriod = routeQueryParams['period'];
              const selectedPeriodFrom = routeQueryParams['periodFrom'];
              const selectedPeriodTo = routeQueryParams['periodTo'];
              const selectedLocal = routeQueryParams['local'];

              return of(
                dashboardInitialiseComplete({
                  periods,
                  lobs: lobs.map((lob) => ({ ...lob, local: lob.local - 1 })), // TODO: Review DB to UI mappings!
                  selectedDashboard,
                  selectedPeriod,
                  selectedPeriodTo,
                  selectedLocal,
                  selectedLobs
                }),
                dashboardSelectedPeriodFrom({ selectedPeriodFrom }),
                dashboardUpdateGrid()
              );
            }),
            catchError((error) =>
              of(
                processError({
                  key: APP_BUSY_INDICATOR,
                  message: 'An error occurred whilst retrieving the Dashboard.',
                  error
                })
              )
            )
          )
        )
      )
    )
  );

  dashboardSelectedPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardSelectedPeriod),
      switchMap(() => of(dashboardUpdateGrid()))
    )
  );

  dashboardDownloadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardDownloadFile),
      mergeMap((action) =>
        concat(
          of(processBusy({ key: action.busyIndicator })),
          this.fileHttpService.getFile(action.filename).pipe(
            switchMap((blob) => {
              FileSaver.saveAs(blob, action.filename);

              return of(processComplete({ key: action.busyIndicator }));
            }),
            catchError((error) =>
              of(processError({ key: action.busyIndicator, message: 'error message', error }))
            )
          )
        )
      )
    )
  );
}
