<header>
  <div class="logo-container">
    <img src="assets/images/logo-gre.svg" alt="GallagherRe Logo" height="40" />
    <img
      class="client-logo"
      [src]="clientLogo$ | async"
      alt="Client logo"
      height="40"
      *ngIf="clientUser$ | async" />
  </div>
  <nav class="menu" role="navigation" *ngIf="adminUser$ | async">
    <re-tabs [tabs]="(adminTabs$ | async)!" [selected]="(navLocation$ | async)!"></re-tabs>
  </nav>
  <nav class="menu" role="navigation" *ngIf="hasPermission$ | async">
    <re-tabs [tabs]="(clientTabs$ | async)!" [selected]="(navLocation$ | async)!"></re-tabs>
  </nav>
  <div class="right-navigation">
    <div class="info-container">
      <img
        src="assets/images/cmi-icons/icon-reg_circle-info.svg"
        alt="info icon"
        (click)="toggleSlideout($event)"
        (keypress)="toggleSlideout($event)"
        tabindex="0" />
    </div>
    <app-user-profile></app-user-profile>
  </div>
</header>
