export const DASHBOARD_FEATURE_KEY = 'dashboard';

export const DASHBOARD_Y_ON_Y = 'Y on Y';
export const DASHBOARD_Q_ON_Q = 'Q on Q';
export const DASHBOARD_TIME_SERIES_Y_ON_Y = 'Time Series (Y on Y)';
export const DASHBOARD_TIME_SERIES_Q_ON_Q = 'Time Series (Q on Q)';

export const DASHBOARD_FILTER_LOBS_MODAL = 'dashboard-filter-lobs-modal';

export const DASHBOARD_SELECTED_LOBS = 'dashboard-selected-lobs';

export const DASHBOARD_TIMELINE_PERIOD = 'Timeline';
export const DASHBOARD_ROW_HEIGHT = 52;
export const DASHBOARD_GRID_HEADER_HEIGHT = 84;
export const DASHBOARD_GRID_PADDING_HEIGHT = 40;
export const DASHBOARD_MIN_ROWS_TO_SHOW = 6;
