import {
  USER_FEATURE_KEY,
  USER_PERMISSION_ADMIN,
  USER_PERMISSION_CLIENT,
  USER_PERMISSION_INTERNAL,
  UserState
} from '@cmi/store/user';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

export const selectUserEmail = createSelector(selectUserState, (state) => state.email);

export const selectUserName = createSelector(selectUserState, (state) =>
  state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : undefined
);

export const selectUserInitials = createSelector(selectUserState, (state) =>
  state.firstName && state.lastName
    ? `${state.firstName.substring(0, 1)}.${state.lastName.substring(0, 1)}`
    : undefined
);

export const selectUserPermissionAdmin = createSelector(selectUserState, (state) =>
  state.permissions.some((permissions) => permissions === USER_PERMISSION_ADMIN)
);

export const selectUserPermissionInternal = createSelector(selectUserState, (state) =>
  state.permissions.some((permissions) => permissions === USER_PERMISSION_INTERNAL)
);

export const selectUserPermissionClient = createSelector(selectUserState, (state) =>
  state.permissions.some((permissions) => permissions === USER_PERMISSION_CLIENT)
);
