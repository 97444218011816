<header aria-hidden="true">
  <h3>Definitions & Sources</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</header>

<re-accordion accordionId="sources-definitions-accordion">
  <ng-template reAccordionItem id="metrics" header="Metrics">
    <section class="metrics-grid">
      <app-ag-grid
        [columnDefs]="metricsColumnDefs"
        [getRowClass]="metricsGetRowClass"
        [rowData]="metricsRowData"></app-ag-grid>
    </section>
  </ng-template>
  <ng-template reAccordionItem id="definitions" header="Definitions">
    <section>
      <h5>International</h5>
      <div>
        Where 'International' is mentioned, insights are for all markets excluding the US market
      </div>
    </section>
    <section>
      <h5>Global</h5>
      <div>Where 'Global' is mentioned, insights are for all markets including the US market</div>
    </section>
  </ng-template>

  <ng-template reAccordionItem id="sources" header="Sources">
    <section>
      <h5>Rate change, Gallagher Specialty</h5>
      <div>
        Rate change is non-exposure, non-risk adjusted, hence the calculation is renewal
        premium/expiring premium. Rate change data is for business placed by Gallagher Specialty in
        the UK. This business is predominantly Wholesale business (i.e. not domestic) and placed in
        the London Market
      </div>
      <h6>Source: Gallagher</h6>
    </section>
    <section>
      <h5>Rate change outcome (per policy)</h5>
      <div>
        Proportion of policies, on a per risk basis, that have had a rate increase/flat
        renewal/decrease. Rate change is non-exposure, non-risk adjusted
      </div>
      <h6>Source: Gallagher</h6>
    </section>
    <section>
      <h5>Rate change, Gallagher domestic</h5>
      <div>
        Rate change is non-exposure, non-risk adjusted, hence the calculation is: renewal
        premium/expiring premium. Rate change data is for business placed by the wider Gallagher
        group in the respective domestic markets. This business is predominantly SME business (i.e.
        not London Market) and placed in the local market
      </div>
      <h6>Source: Gallagher</h6>
    </section>
    <section>
      <h5>Renewal Remarket Trend</h5>
      <div>Percentage of business that is getting marketed at renewal</div>
      <h6>Source: Gallagher</h6>
    </section>
    <section>
      <h5>Gallagher Re rate change</h5>
      <div>
        <p>
          <strong>2019-2023:</strong> Risk adjusted rate change for 2019-2023. Data is Gallagher Re
          proprietary data
        </p>
        <p>
          <strong>2024 (planned):</strong> Planned risk adjusted rate change for 2024. Data is
          Gallagher Re proprietary data
        </p>
      </div>
      <h6>Source: Gallagher</h6>
    </section>
    <section>
      <h5>Gallagher Re compound rate change</h5>
      <div>Compound rate change since 2018, data is Gallagher Re proprietary data</div>
      <h6>Source: Gallagher Re</h6>
    </section>
    <section>
      <h5>Loss ratio by year, planned gross loss ratio</h5>
      <div>
        Average of planned loss ratios, predominantly on an underwriting year basis but can contain
        data on other bases. Data is Gallagher Re proprietary data
      </div>
      <h6>Source: Gallagher Re</h6>
    </section>
    <section>
      <h5>Loss ratio by year, actual gross loss ratio</h5>
      <div>
        Projected ultimate gross loss ratios. Loss ratio projected to ultimate by Gallagher Re
      </div>
      <h6>Source: Gallagher Re</h6>
    </section>
  </ng-template>
</re-accordion>
