import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class FileHttpService {
  private baseUrl = `${environment.cmiApiUri}`;

  constructor(private httpClient: HttpClient) {}

  getFile(filename: string): Observable<Blob> {
    const url = `${this.baseUrl}/api/file/${filename}`;

    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
