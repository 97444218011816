import { ModalComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DashboardState, selectDashboardExecutiveSummary } from '@cmi/store/dashboard';
import { Store } from '@ngrx/store';
import { QuillViewHTMLComponent } from 'ngx-quill';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-executive-summary-modal',
  standalone: true,
  imports: [CommonModule, ModalComponent, QuillViewHTMLComponent],
  templateUrl: './dashboard-executive-summary-modal.component.html',
  styleUrl: './dashboard-executive-summary-modal.component.scss'
})
export class DashboardExecutiveSummaryModalComponent implements OnInit {
  selectExecutiveSummary$!: Observable<string>;

  constructor(private store: Store<DashboardState>) {}

  ngOnInit() {
    this.selectExecutiveSummary$ = this.store.select(selectDashboardExecutiveSummary);
  }
}
