import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class HeaderComponentService {
  private headerComponent$ = new BehaviorSubject<unknown>(null);

  setHeaderComponent(component: unknown) {
    this.headerComponent$.next(component);
  }

  clearHeaderComponent() {
    this.headerComponent$.next(null);
  }

  headerComponent(): Observable<unknown> {
    return this.headerComponent$ as Observable<unknown>;
  }
}
