import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { SlideoutService } from '@cmi/shared/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-slideout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './slideout.component.html',
  styleUrl: './slideout.component.scss',
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '515px',
          borderWidth: '1px',
          paddingLeft: '16px',
          paddingRight: '16px'
        })
      ),
      state(
        'closed',
        style({
          width: '0',
          borderWidth: '0',
          paddingLeft: '0',
          paddingRight: '0'
        })
      ),
      transition('open <=> closed', [animate('300ms ease-in')])
    ])
  ]
})
export class SlideoutComponent implements OnInit {
  isSlideoutOpen$!: Observable<boolean>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component$!: Observable<any>;

  constructor(private slideoutService: SlideoutService) {}

  ngOnInit() {
    this.isSlideoutOpen$ = this.slideoutService.slideoutOpen();
    this.component$ = this.slideoutService.slideoutComponent();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  @HostListener('document:click')
  outsideClick() {
    this.slideoutService.closeSlideout();
  }
}
