<section class="profile-initials">
  <div>{{ userProfileInitials$ | async }}</div>
</section>
<section class="profile-name-role">
  <div>{{ userProfileName$ | async }}</div>
  <!-- TODO: Left in commented to be looked at later -->
  <!-- <div>{{ userProfileRole$ | async }}</div> -->
</section>
<section class="profile-chevron-container">
  <img
    [src]="(chevron$ | async)?.src"
    [alt]="(chevron$ | async)?.alt"
    (click)="toggleUserProfile($event)"
    (keypress)="toggleUserProfile($event)"
    tabindex="0" />
</section>
<div
  aria-hidden="true"
  class="user-profile-details"
  *ngIf="showUserProfile$ | async"
  (click)="stopPropagation($event)">
  <div>{{ userProfileName$ | async }}</div>
  <div>{{ userProfileEmail$ | async }}</div>
  <hr />
  <re-button
    class="re-secondary"
    leftIconSrc="assets/images/cmi-icons/logo-reg_arrow-left-from-bracket.svg"
    leftIconAlt="log out icon"
    (clicked)="logout()">
    SIGN OUT</re-button
  >
</div>
