import { Injectable } from '@angular/core';
import { DASHBOARD_SELECTED_LOBS } from '@cmi/store/dashboard';
import { Observable, of } from 'rxjs';

@Injectable()
export class LocalStorageService {
  selectedLobs(): Observable<number[] | undefined> {
    const selectedLobs = localStorage.getItem(DASHBOARD_SELECTED_LOBS);

    return of(selectedLobs ? JSON.parse(selectedLobs) : undefined);
  }

  saveSelectedLobs(selectedLobs?: number[]): Observable<boolean> {
    if (!selectedLobs) {
      localStorage.removeItem(DASHBOARD_SELECTED_LOBS);
    } else {
      localStorage.setItem(DASHBOARD_SELECTED_LOBS, JSON.stringify(selectedLobs));
    }

    return of(true);
  }
}
