import { Injectable } from '@angular/core';
import { APP_NARRATIVE_BUSY_INDICATOR } from '@cmi/store/app';
import { processBusy, processComplete, processError } from '@cmi/store/process';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concat, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { DASHBOARD_TIME_SERIES_Y_ON_Y, DASHBOARD_TIME_SERIES_Q_ON_Q } from './dashboard-constants';
import { DashboardState } from './dashboard-state';
import {
  dashboardInitialiseKpiNarrative,
  dashboardInitialiseKpiNarrativeComplete
} from './dashboard.actions';
import { selectDashboardSelectedDashboard } from './dashboard.selectors';
import { KpiNarrative } from './models/kpi-narrative.model';
import { DashboardHttpService } from './services/dashboard-http.service';

@Injectable()
export class DashboardKpiNarrativeEffects {
  constructor(
    private actions$: Actions,
    private store: Store<DashboardState>,
    private dashboardHttpService: DashboardHttpService
  ) {}

  dashboardInitialiseKpiNarrative$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardInitialiseKpiNarrative),
      withLatestFrom(this.store.select(selectDashboardSelectedDashboard)),
      switchMap(([_action, dashboardType]) => {
        let kpiNarrativeObservable!: Observable<KpiNarrative[]>;

        if (dashboardType === DASHBOARD_TIME_SERIES_Y_ON_Y) {
          kpiNarrativeObservable = this.dashboardHttpService.getDashboardYonYKpiNarrative(
            _action.kpi,
            _action.subLobId
          );
        }

        if (dashboardType === DASHBOARD_TIME_SERIES_Q_ON_Q) {
          kpiNarrativeObservable = this.dashboardHttpService.getDashboardQonQKpiNarrative(
            _action.kpi,
            _action.subLobId
          );
        }

        return concat(
          of(processBusy({ key: APP_NARRATIVE_BUSY_INDICATOR })),
          kpiNarrativeObservable.pipe(
            switchMap((data) => {
              return of(
                dashboardInitialiseKpiNarrativeComplete({ kpiNarrativeList: data }),
                processComplete({ key: APP_NARRATIVE_BUSY_INDICATOR })
              );
            }),
            catchError((error) =>
              of(
                processError({
                  key: APP_NARRATIVE_BUSY_INDICATOR,
                  message: 'An error occurred whilst retrieving the KPI narrative.',
                  error
                })
              )
            )
          )
        );
      })
    )
  );
}
