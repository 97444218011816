import { Injectable } from '@angular/core';
import { APP_BUSY_INDICATOR } from '@cmi/store/app';
import {
  DashboardState,
  dashboardUpdateGrid,
  dashboardUpdateYonYData,
  selectDashboardState,
  DashboardHttpService,
  dashboardUpdateQonQData,
  dashboardUpdateExecutiveSummary,
  dashboardUpdateTimeSeriesYonYData,
  dashboardUpdateTimeSeriesQonQData
} from '@cmi/store/dashboard';
import { processBusy, processComplete, processError } from '@cmi/store/process';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, switchMap, withLatestFrom, concat, catchError, forkJoin, exhaustMap } from 'rxjs';

@Injectable()
export class DashboardGridEffects {
  constructor(
    private actions$: Actions,
    private store: Store<DashboardState>,
    private dashboardHttpService: DashboardHttpService
  ) {}

  dashboardUpdateGrid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardUpdateGrid),
      withLatestFrom(this.store.select(selectDashboardState)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      exhaustMap(([_action, state]) =>
        concat(
          of(processBusy({ key: APP_BUSY_INDICATOR })),
          forkJoin([
            this.dashboardHttpService.getDashboardExecutiveSummary(state.selectedPeriod),
            this.dashboardHttpService.getDashboardYonYData(state.selectedPeriod),
            this.dashboardHttpService.getDashboardQonQData(state.selectedPeriod),
            this.dashboardHttpService.getDashboardTimeSeriesYonYData(),
            this.dashboardHttpService.getDashboardTimeSeriesQonQData()
          ]).pipe(
            switchMap(
              ([
                executiveSummary,
                yonyGridData,
                qonqGridData,
                timeSeriesYonYGridData,
                timeSeriesQonQGridData
              ]) =>
                of(
                  dashboardUpdateExecutiveSummary({ executiveSummary }),
                  dashboardUpdateYonYData({ yonyGridData }),
                  dashboardUpdateQonQData({ qonqGridData }),
                  dashboardUpdateTimeSeriesYonYData({ timeSeriesYonYGridData }),
                  dashboardUpdateTimeSeriesQonQData({ timeSeriesQonQGridData }),
                  processComplete({ key: APP_BUSY_INDICATOR })
                )
            ),
            catchError((error) =>
              of(
                processError({
                  key: APP_BUSY_INDICATOR,
                  message: 'An error occurred whilst retrieving the Dashboard.',
                  error
                })
              )
            )
          )
        )
      )
    )
  );
}
