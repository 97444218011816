import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-anchor-cell',
  standalone: true,
  imports: [],
  templateUrl: 'anchor-cell.component.html',
  styleUrl: 'anchor-cell.component.scss'
})
export class AnchorCellComponent implements ICellRendererAngularComp {
  label!: string;
  private url = '';

  constructor(private router: Router) {}

  agInit(params: ICellRendererParams) {
    this.label = params?.value.label;
    this.url = params?.value.url;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(_params: ICellRendererParams): boolean {
    return false;
  }

  openLob() {
    this.router.navigate([this.url]);

    return false;
  }
}
