import { BusyComponent } from '@ajgre/toolkit';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { FooterComponent, HeaderComponent, SlideoutComponent } from '@cmi/shared/components';
import { HeaderComponentService } from '@cmi/shared/services';
import {
  APP_BUSY_INDICATOR,
  APP_HEADER_HEIGHT,
  APP_INITIALISE_BUSY_INDICATOR,
  appHeight,
  appInitialise,
  AppState,
  selectAppHeight
} from '@cmi/store/app';
import {
  ProcessEntityState,
  processRemove,
  selectProcessBusy,
  selectProcessBusyMessage
} from '@cmi/store/process';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

@Component({
  selector: 'app-route-component',
  standalone: true,
  imports: [
    RouterOutlet,
    CommonModule,
    BusyComponent,
    HeaderComponent,
    FooterComponent,
    SlideoutComponent
  ],
  templateUrl: './route-component.component.html',
  styleUrl: './route-component.component.scss'
})
export class RouteComponent implements OnInit, OnDestroy {
  @ViewChild('header', { static: true }) header = {} as ElementRef<HTMLElement>;
  @ViewChild('body', { static: true }) body = {} as ElementRef<HTMLElement>;

  busy$!: Observable<boolean>;
  busyMessage$!: Observable<string>;
  height$!: Observable<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerComponent$!: Observable<any>;
  headerHeight$ = new BehaviorSubject<number>(0);
  marginOffset$ = new BehaviorSubject<string>('0px');

  private headerObserver!: ResizeObserver;
  private bodyObserver!: ResizeObserver;

  constructor(
    private store: Store<AppState | ProcessEntityState>,
    private headerComponentService: HeaderComponentService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.store.dispatch(appInitialise());
    this.store.dispatch(appHeight({ height: this.document.defaultView?.innerHeight ?? 0 }));

    this.busy$ = combineLatest([
      this.store.select(selectProcessBusy(APP_INITIALISE_BUSY_INDICATOR)),
      this.store.select(selectProcessBusy(APP_BUSY_INDICATOR))
    ]).pipe(map(([initialiseBusy, busy]) => initialiseBusy || busy));
    this.busyMessage$ = this.store
      .select(selectProcessBusyMessage(APP_BUSY_INDICATOR))
      .pipe(map((message) => message ?? 'Content Loading...'));
    this.headerComponent$ = this.headerComponentService.headerComponent();
    this.height$ = combineLatest([
      this.store.select(selectAppHeight),
      this.headerHeight$,
      this.busy$
    ]).pipe(
      map(([windowHeight, headerHeight]) => `${windowHeight - APP_HEADER_HEIGHT - headerHeight}px`)
    );

    this.headerObserver = new ResizeObserver((entries) => {
      this.ngZone.run(() => {
        this.headerHeight$.next(entries[0].contentRect.height);
      });
    });
    this.bodyObserver = new ResizeObserver(() => {
      this.ngZone.run(() => {
        this.marginOffset$.next(
          `${this.body.nativeElement.offsetWidth - this.body.nativeElement.scrollWidth}px`
        );
      });
    });

    this.headerObserver.observe(this.header.nativeElement);
    this.bodyObserver.observe(this.body.nativeElement);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerHeight: number } }) {
    this.store.dispatch(appHeight({ height: event.target.innerHeight }));
  }

  ngOnDestroy() {
    this.store.dispatch(processRemove({ key: APP_INITIALISE_BUSY_INDICATOR }));
    this.store.dispatch(processRemove({ key: APP_BUSY_INDICATOR }));

    this.headerObserver.disconnect();
    this.bodyObserver.disconnect();
  }
}
