import { AccordionComponent, AccordionItemDirective } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AgGridComponent, metricsColumnDefs, metricsGetRowClass } from '@cmi/shared/components';
import { SlideoutService } from '@cmi/shared/services';
import { GridKpi, Kpi, MetricsLob } from '@cmi/store/app';

@Component({
  selector: 'app-definitions-slideout',
  standalone: true,
  imports: [CommonModule, AccordionComponent, AccordionItemDirective, AgGridComponent],
  templateUrl: './definitions-slideout.component.html',
  styleUrl: './definitions-slideout.component.scss'
})
export class DefinitionsSlideoutComponent {
  metricsColumnDefs = metricsColumnDefs;
  metricsGetRowClass = metricsGetRowClass;
  metricsRowData = this.metricsData;

  constructor(private slideoutService: SlideoutService) {}

  close() {
    this.slideoutService.closeSlideout();

    return false;
  }

  private get metricsData(): MetricsLob[] {
    return [
      {
        lob: 'Pricing'
      },
      {
        lob: 'Pricing',
        kpi: { kpi: Kpi.FavourableUp } as GridKpi,
        description: 'Rate changes are increasing'
      },
      {
        lob: 'Pricing',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'Rate changes are largely remaining flat'
      },
      {
        lob: 'Pricing',
        kpi: { kpi: Kpi.UnfavourableDown } as GridKpi,
        description: 'Rate changes are decreasing'
      },
      {
        lob: 'Capacity'
      },
      {
        lob: 'Capacity',
        kpi: { kpi: Kpi.FavourableDown } as GridKpi,
        description: 'Capacity is decreasing in the market'
      },
      {
        lob: 'Capacity',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'Capacity is largely remaining flat in the market'
      },
      {
        lob: 'Capacity',
        kpi: { kpi: Kpi.UnfavourableUp } as GridKpi,
        description: 'Capacity is increasing in the market'
      },
      {
        lob: 'Competition'
      },
      {
        lob: 'Competition',
        kpi: { kpi: Kpi.FavourableDown } as GridKpi,
        description: 'Competition is decreasing'
      },
      {
        lob: 'Competition',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'Competition is remaining flat'
      },
      {
        lob: 'Competition',
        kpi: { kpi: Kpi.UnfavourableUp } as GridKpi,
        description: 'Competition is increasing'
      },
      {
        lob: 'Reinsurance'
      },
      {
        lob: 'Reinsurance',
        kpi: { kpi: Kpi.FavourableDown } as GridKpi,
        description: 'Reinsurance costs are decreasing'
      },
      {
        lob: 'Reinsurance',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'Reinsurance costs remaining flat'
      },
      {
        lob: 'Reinsurance',
        kpi: { kpi: Kpi.UnfavourableUp } as GridKpi,
        description: 'Reinsurance costs increasing'
      },
      {
        lob: 'Profitability'
      },
      {
        lob: 'Profitability',
        kpi: { kpi: Kpi.FavourableUp } as GridKpi,
        description: 'Profitability is increasing in the market'
      },
      {
        lob: 'Profitability',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'Profitability remaining flat in the market'
      },
      {
        lob: 'Profitability',
        kpi: { kpi: Kpi.UnfavourableDown } as GridKpi,
        description: 'Profitability is decreasing in the market'
      },
      {
        lob: 'Claims dynamics'
      },
      {
        lob: 'Claims dynamics',
        kpi: { kpi: Kpi.FavourableDown } as GridKpi,
        description: 'Claims trends are favourable for carriers'
      },
      {
        lob: 'Claims dynamics',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'Claims trends are remaining flat'
      },
      {
        lob: 'Claims dynamics',
        kpi: { kpi: Kpi.UnfavourableUp } as GridKpi,
        description: 'Claims trends are unfavourable for carriers'
      },
      {
        lob: 'T & Cs'
      },
      {
        lob: 'T & Cs',
        kpi: { kpi: Kpi.FavourableUp } as GridKpi,
        description: 'T&Cs are tightening'
      },
      {
        lob: 'T & Cs',
        kpi: { kpi: Kpi.Stable } as GridKpi,
        description: 'T&Cs remaining the same'
      },
      {
        lob: 'T & Cs',
        kpi: { kpi: Kpi.UnfavourableDown } as GridKpi,
        description: 'T&Cs are loosening'
      }
    ];
  }
}
