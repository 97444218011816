<div>
  <re-radio
    [formConfig]="localGlobalInternConfig"
    [value]="local"
    (valueChange)="valueChange($event)"></re-radio>
  <re-radio
    [formConfig]="localUSConfig"
    [value]="local"
    (valueChange)="valueChange($event)"></re-radio>
</div>
