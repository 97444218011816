import { Injectable } from '@angular/core';
import {
  APP_INITIALISE_BUSY_INDICATOR,
  AppHttpService,
  appInitialise,
  AppState,
  appUpdateCompanyLogo,
  selectAppCompanyLogo
} from '@cmi/store/app';
import { processBusy, processComplete, processError } from '@cmi/store/process';
import { selectUserState, userUpdate } from '@cmi/store/user';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  asyncScheduler,
  catchError,
  concat,
  forkJoin,
  Observable,
  observeOn,
  of,
  switchMap,
  withLatestFrom
} from 'rxjs';
import { AuthorisationService, UserProfile } from 'wre-authlib';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private store: Store<UserProfile | AppState>,
    private authorisationService: AuthorisationService,
    private appHttpService: AppHttpService
  ) {}

  appInitialise$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appInitialise),
      observeOn(asyncScheduler),
      withLatestFrom(this.store.select(selectUserState), this.store.select(selectAppCompanyLogo)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_action, userState, companyLogo]) => {
        const { permissions, ...userProfile } = userState;

        return concat(
          of(processBusy({ key: APP_INITIALISE_BUSY_INDICATOR })),
          forkJoin([
            userProfile.email
              ? of(userProfile as UserProfile)
              : this.authorisationService.getUser(),
            (permissions ?? []).length > 0
              ? of(permissions)
              : this.authorisationService.getUserApplicationRolesWithPermissions()
          ]).pipe(
            switchMap(([userProfile, permissions]) =>
              (companyLogo
                ? of(null)
                : (this.appHttpService.getCompanyLogo() as Observable<Blob | null>)
              ).pipe(
                switchMap((blob) =>
                  of(
                    userUpdate({
                      userProfile,
                      permissions: (permissions ?? []).filter((perm) => perm.startsWith('CMI'))
                    }),
                    appUpdateCompanyLogo({
                      companyLogo: companyLogo || !blob ? companyLogo : URL.createObjectURL(blob!)
                    }),
                    processComplete({ key: APP_INITIALISE_BUSY_INDICATOR })
                  )
                ),
                catchError((error) =>
                  of(
                    userUpdate({
                      userProfile,
                      permissions: (permissions ?? []).filter((perm) => perm.startsWith('CMI'))
                    }),
                    processError({
                      key: APP_INITIALISE_BUSY_INDICATOR,
                      message: 'An error occurred whilst retrieving the company logo.',
                      error
                    })
                  )
                )
              )
            ),
            catchError((error) =>
              of(
                processError({
                  key: APP_INITIALISE_BUSY_INDICATOR,
                  message: 'An error occurred whilst retrieving the user details.',
                  error
                })
              )
            )
          )
        );
      })
    )
  );
}
