import { MODAL_FEATURE_KEY, selectEntities, ModalEntityState, ModalState } from '@cmi/store/modal';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectModalEntityState = createFeatureSelector<ModalEntityState>(MODAL_FEATURE_KEY);

export const selectModalEntities = createSelector(selectModalEntityState, (state) =>
  selectEntities(state)
);

export const selectModalState = (key: string) =>
  createSelector(selectModalEntities, (entities) => entities[key]?.modalState ?? ModalState.Closed);
