import { Period } from '@cmi/shared/models';
import { LOCAL_GLOBAL_INTERNATIONAL, LOCAL_US } from '@cmi/store/app';
import {
  DASHBOARD_Y_ON_Y,
  dashboardSelectedPeriod,
  DashboardState,
  dashboardSelectedDashboard,
  dashboardUpdateExecutiveSummary,
  dashboardExecutiveSummaryOpen,
  dashboardSelectedLocal,
  dashboardUpdateYonYData,
  dashboardSelectedLobs,
  dashboardUpdateQonQData,
  dashboardInitialiseComplete,
  dashboardSelectedPeriodFrom,
  dashboardSelectedPeriodTo,
  dashboardUpdateTimeSeriesYonYData,
  dashboardUpdateTimeSeriesQonQData,
  dashboardUpdateLobs,
  dashboardOpenKpiNarrativeSlideout,
  DASHBOARD_TIME_SERIES_Y_ON_Y,
  DASHBOARD_Q_ON_Q,
  DASHBOARD_TIME_SERIES_Q_ON_Q,
  dashboardInitialiseKpiNarrativeComplete,
  dashboardSelectedPeriodNoEffect,
  dashboardInitialise
} from '@cmi/store/dashboard';
import { createReducer, on } from '@ngrx/store';

export const dashboardInitialState = {
  periods: [],
  lobs: [],
  executiveSummary: '',
  executiveSummaryOpen: true,
  selectedDashboard: DASHBOARD_Y_ON_Y,
  selectedPeriod: '',
  selectedPeriodFrom: '',
  selectedPeriodTo: '',
  selectedLocal: LOCAL_GLOBAL_INTERNATIONAL,
  selectedLobs: undefined,
  yonyGridData: [],
  qonqGridData: [],
  timeSeriesYonYGridData: [],
  timeSeriesQonQGridData: [],
  kpiNarrativeSlideoutData: undefined,
  selectedKpiNarrative: []
} as DashboardState;

export const dashboardReducer = createReducer(
  dashboardInitialState,
  on(dashboardInitialise, (state) => ({
    ...state,
    yonyGridData: [],
    qonqGridData: [],
    timeSeriesYonYGridData: [],
    timeSeriesQonQGridData: []
  })),
  on(dashboardInitialiseComplete, (state, prop) => {
    const selectedDashboard = getValidDashboard(prop.selectedDashboard, state.selectedDashboard);
    const selectedPeriod = getValidPeriod(prop.periods, prop.selectedPeriod, state.selectedPeriod);
    const selectedPeriodTo = getValidPeriod(
      prop.periods,
      prop.selectedPeriodTo,
      state.selectedPeriodTo
    );
    const selectedLocal = getValidLocal(prop.selectedLocal, state.selectedLocal);

    return {
      ...state,
      periods: prop.periods,
      lobs: prop.lobs,
      selectedDashboard,
      selectedPeriod,
      selectedPeriodTo,
      selectedLocal,
      selectedLobs: prop.selectedLobs
    };
  }),
  on(dashboardUpdateLobs, (state, prop) => ({ ...state, lobs: prop.lobs })),
  on(dashboardUpdateExecutiveSummary, (state, prop) => ({
    ...state,
    executiveSummary: prop.executiveSummary
  })),
  on(dashboardExecutiveSummaryOpen, (state, prop) => ({
    ...state,
    executiveSummaryOpen: prop.executiveSummaryOpen
  })),
  on(dashboardSelectedDashboard, (state, prop) => ({
    ...state,
    selectedDashboard: prop.selectedDashboard
  })),
  on(dashboardSelectedPeriod, (state, prop) => ({ ...state, selectedPeriod: prop.selectedPeriod })),
  on(dashboardSelectedPeriodNoEffect, (state, prop) => ({
    ...state,
    selectedPeriod: prop.selectedPeriod
  })),
  on(dashboardSelectedPeriodFrom, (state, prop) => {
    const validPeriodFrom = getValidPeriod(
      state.periods,
      prop.selectedPeriodFrom,
      state.selectedPeriodFrom,
      true
    );

    const periodFrom = (state.periods ?? []).find((period) => period.periodId === validPeriodFrom);
    const periodTo = (state.periods ?? []).find(
      (period) => period.periodId === state.selectedPeriodTo
    );

    const selectedPeriodTo =
      periodFrom && periodTo && periodFrom.dateTo > periodTo.dateTo
        ? validPeriodFrom
        : state.selectedPeriodTo;

    return {
      ...state,
      selectedPeriodFrom: validPeriodFrom,
      selectedPeriodTo
    };
  }),
  on(dashboardSelectedPeriodTo, (state, prop) => {
    const periodFrom = (state.periods ?? []).find(
      (period) => period.periodId === state.selectedPeriodFrom
    );
    const periodTo = (state.periods ?? []).find(
      (period) => period.periodId === prop.selectedPeriodTo
    );

    const selectedPeriodFrom =
      periodFrom && periodTo && periodFrom.dateTo > periodTo.dateTo
        ? prop.selectedPeriodTo
        : state.selectedPeriodFrom;

    return {
      ...state,
      selectedPeriodFrom,
      selectedPeriodTo: prop.selectedPeriodTo
    };
  }),
  on(dashboardSelectedLocal, (state, prop) => ({ ...state, selectedLocal: prop.selectedLocal })),
  on(dashboardSelectedLobs, (state, prop) => ({ ...state, selectedLobs: prop.selectedLobs })),
  on(dashboardUpdateYonYData, (state, prop) => ({ ...state, yonyGridData: prop.yonyGridData })),
  on(dashboardUpdateQonQData, (state, prop) => ({ ...state, qonqGridData: prop.qonqGridData })),
  on(dashboardUpdateTimeSeriesYonYData, (state, prop) => ({
    ...state,
    timeSeriesYonYGridData: prop.timeSeriesYonYGridData
  })),
  on(dashboardUpdateTimeSeriesQonQData, (state, prop) => ({
    ...state,
    timeSeriesQonQGridData: prop.timeSeriesQonQGridData
  })),
  on(dashboardOpenKpiNarrativeSlideout, (state, prop) => ({
    ...state,
    kpiNarrativeSlideoutData: prop.kpiNarrativeData
  })),
  on(dashboardInitialiseKpiNarrativeComplete, (state, prop) => ({
    ...state,
    selectedKpiNarrative: prop.kpiNarrativeList
  }))
);

function getValidPeriod(
  periods: Period[],
  newPeriod: string,
  oldPeriod: string,
  defaultLast = false
): string {
  if ((periods ?? []).some((p) => p.periodId === newPeriod)) {
    return newPeriod;
  } else if ((periods ?? []).some((p) => p.periodId === oldPeriod)) {
    return oldPeriod;
  }

  return (periods ?? []).length === 0
    ? ''
    : defaultLast
      ? periods[periods.length - 1].periodId
      : periods[0].periodId;
}

function getValidDashboard(newDashboard: string, oldDashboard: string) {
  return isDashboardValid(newDashboard)
    ? newDashboard
    : isDashboardValid(oldDashboard)
      ? oldDashboard
      : DASHBOARD_Y_ON_Y;
}

function isDashboardValid(dashboardType: string) {
  return [
    DASHBOARD_Y_ON_Y,
    DASHBOARD_Q_ON_Q,
    DASHBOARD_TIME_SERIES_Y_ON_Y,
    DASHBOARD_TIME_SERIES_Q_ON_Q
  ].some((d) => d === dashboardType);
}

function getValidLocal(newLocal: string, oldLocal: string) {
  return isLocalValid(newLocal)
    ? newLocal
    : isLocalValid(oldLocal)
      ? oldLocal
      : LOCAL_GLOBAL_INTERNATIONAL;
}

function isLocalValid(local: string) {
  return [LOCAL_GLOBAL_INTERNATIONAL, LOCAL_US].some((l) => l === local);
}
