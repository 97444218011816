import { ButtonComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { SlideoutService } from '@cmi/shared/services';
import { selectUserEmail, selectUserInitials, selectUserName } from '@cmi/store/user';
import { Store } from '@ngrx/store';
import { map, Observable, of } from 'rxjs';
import { AuthenticationService, UserProfile } from 'wre-authlib';

@Component({
  selector: 'app-user-profile',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './user-profile.component.html',
  styleUrl: './user-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserProfileComponent implements OnInit {
  userProfileInitials$!: Observable<string | undefined>;
  userProfileName$!: Observable<string | undefined>;
  userProfileRole$: Observable<string> = of('');
  userProfileEmail$!: Observable<string | undefined>;
  showUserProfile$!: Observable<boolean>;
  chevron$!: Observable<{ src: string; alt: string }>;

  constructor(
    private store: Store<UserProfile>,
    private authenticationService: AuthenticationService,
    private slideoutService: SlideoutService
  ) {}

  ngOnInit() {
    this.userProfileInitials$ = this.store.select(selectUserInitials);
    this.userProfileName$ = this.store.select(selectUserName);
    this.userProfileEmail$ = this.store.select(selectUserEmail);
    this.showUserProfile$ = this.slideoutService.userProfileOpen();
    this.chevron$ = this.showUserProfile$.pipe(
      map((userProfileOpen) =>
        userProfileOpen
          ? {
              src: 'assets/images/cmi-icons/icon-reg_chevron-up.svg',
              alt: 'chevron up icon'
            }
          : {
              src: 'assets/images/cmi-icons/icon-reg_chevron-down.svg',
              alt: 'chevron down icon'
            }
      )
    );
  }

  toggleUserProfile(event: Event) {
    this.slideoutService.toggleUserProfile();

    event.stopPropagation();
  }

  logout() {
    this.authenticationService.logout();
  }

  @HostListener('document:click')
  outsideClick() {
    this.slideoutService.closeUserProfile();
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
