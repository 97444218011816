import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';

@Injectable()
export class PdfViewerNotifyService implements OnDestroy {
  private windowMap = new Map<string, Window | null | undefined>();

  private channel!: BroadcastChannel;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.channel = new BroadcastChannel('pdf-viewer');
  }

  notify(filename: string, section: string) {
    let window = this.windowMap.get(filename);

    const hash = isNaN(+section) ? section : `page=${section}`;

    if (window && !window.closed) {
      this.channel.postMessage({ filename, section: hash });

      window.focus();
    } else {
      window = this.document.defaultView?.open(`/pdf-viewer/${filename}#${hash}`, '_blank');

      this.windowMap.set(filename, window);
    }
  }

  ngOnDestroy() {
    this.channel.close();
  }
}
