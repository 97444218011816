<app-slideout></app-slideout>
<app-header></app-header>
<hr />
<section class="d-flex flex-column">
  <header #header class="align-self-center">
    <ng-container *ngIf="(busy$ | async) !== true">
      <ng-container *ngComponentOutlet="headerComponent$ | async"></ng-container>
    </ng-container>
  </header>
  <article
    #body
    class="d-flex flex-column justify-content-between align-items-center"
    [style.height]="height$ | async">
    <div class="d-flex flex-column" [style.marginLeft]="marginOffset$ | async">
      <re-busy [busy]="busy$ | async" [message]="(busyMessage$ | async)!"></re-busy>
      <router-outlet></router-outlet>
    </div>
    <app-footer [paddingOffset]="(marginOffset$ | async)!"></app-footer>
  </article>
</section>
