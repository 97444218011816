import { Injectable } from '@angular/core';
import {
  DashboardExecutiveSummaryModalComponent,
  DashboardFilterLobsModalComponent,
  DASHBOARD_FILTER_LOBS_MODAL,
  dashboardExecutiveSummaryModalOpen,
  dashboardFilterLobsModalOpen,
  dashboardFilterLobsModalSave,
  dashboardSelectedLobs,
  LocalStorageService
} from '@cmi/store/dashboard';
import { modalClosed, modalClosing, modalOpen } from '@cmi/store/modal';
import { processBusy, processComplete, processError } from '@cmi/store/process';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concat, of, switchMap, EMPTY, map, merge } from 'rxjs';

@Injectable()
export class DashboardModalEffects {
  constructor(
    private actions$: Actions,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService
  ) {}

  dashboardExecutiveSummaryModalOpen$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(dashboardExecutiveSummaryModalOpen),
        switchMap(() =>
          this.modalService
            .open(DashboardExecutiveSummaryModalComponent, {
              centered: true,
              scrollable: true,
              size: 'lg'
            })
            .closed.pipe(map(() => EMPTY))
        )
      ),
    {
      dispatch: false
    }
  );

  dashboardFilterLobsModalOpen$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardFilterLobsModalOpen),
      switchMap(() =>
        concat(
          of(
            processBusy({ key: DASHBOARD_FILTER_LOBS_MODAL }),
            modalOpen({ key: DASHBOARD_FILTER_LOBS_MODAL })
          ),
          this.localStorageService.selectedLobs().pipe(
            switchMap((selectedLobs) =>
              of(
                dashboardSelectedLobs({ selectedLobs }),
                processComplete({ key: DASHBOARD_FILTER_LOBS_MODAL })
              )
            ),
            catchError((error) =>
              of(
                modalClosing({ key: DASHBOARD_FILTER_LOBS_MODAL }),
                processError({
                  key: DASHBOARD_FILTER_LOBS_MODAL,
                  message: 'An error occurred whilst retrieving the LOBs.',
                  error
                })
              )
            )
          ),
          (() => {
            const modalRef = this.modalService.open(DashboardFilterLobsModalComponent, {
              centered: true,
              size: 'md'
            });

            return merge(modalRef.closed, modalRef.dismissed).pipe(
              switchMap(() => of(modalClosed({ key: DASHBOARD_FILTER_LOBS_MODAL })))
            );
          })()
        )
      )
    )
  );

  dashboardFilterLobsModalSave$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardFilterLobsModalSave),
      switchMap((action) =>
        concat(
          of(processBusy({ key: DASHBOARD_FILTER_LOBS_MODAL })),
          this.localStorageService.saveSelectedLobs(action.selectedLobs).pipe(
            switchMap(() =>
              of(
                dashboardSelectedLobs({ selectedLobs: action.selectedLobs }),
                modalClosing({ key: DASHBOARD_FILTER_LOBS_MODAL }),
                processComplete({ key: DASHBOARD_FILTER_LOBS_MODAL })
              )
            ),
            catchError((error) =>
              of(
                processError({
                  key: DASHBOARD_FILTER_LOBS_MODAL,
                  message: 'An error occurred whilst saving the LOBs.',
                  error
                })
              )
            )
          )
        )
      )
    )
  );
}
