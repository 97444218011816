import { PillComponent } from '@ajgre/toolkit';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-pill-cell',
  standalone: true,
  imports: [PillComponent],
  templateUrl: './pill-cell.component.html',
  styleUrl: './pill-cell.component.scss'
})
export class PillCellComponent implements ICellRendererAngularComp {
  label!: string;
  class!: string;

  agInit(params: ICellRendererParams) {
    this.label = params?.value.label;
    this.class = params?.value.class;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh(_params: ICellRendererParams): boolean {
    return false;
  }
}
