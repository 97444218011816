import {
  appHeight,
  appUpdateCompanyLogo,
  AppState,
  NAV_DASHBOARDS,
  appUpdateNavLocation
} from '@cmi/store/app';
import { createReducer, on } from '@ngrx/store';

export const appInitialState: AppState = {
  companyLogo: '',
  navLocation: NAV_DASHBOARDS,
  navRoot: false,
  height: 0
};

export const appReducer = createReducer(
  appInitialState,
  on(appUpdateCompanyLogo, (state, prop) => ({
    ...state,
    companyLogo: prop.companyLogo
  })),
  on(appUpdateNavLocation, (state, prop) => ({
    ...state,
    navLocation: prop.navLocation,
    navRoot: prop.navRoot
  })),
  on(appHeight, (state, prop) => ({ ...state, height: prop.height }))
);
