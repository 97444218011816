import { Injectable } from '@angular/core';
import { DefinitionsSlideoutComponent } from '@cmi/shared/components';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SlideoutService {
  private slideoutComponent$ = new BehaviorSubject<unknown>(DefinitionsSlideoutComponent);
  private slideoutOpen$ = new BehaviorSubject<boolean>(false);
  private userProfileOpen$ = new BehaviorSubject<boolean>(false);

  openSlideoutComponent(component: unknown) {
    this.slideoutComponent$.next(component);
    this.slideoutOpen$.next(true);
    this.userProfileOpen$.next(false);
  }

  toggleSlideoutComponent(component: unknown) {
    if (this.slideoutOpen$.value && this.slideoutComponent$.value === component) {
      this.slideoutOpen$.next(false);
    } else {
      this.slideoutComponent$.next(component);
      this.slideoutOpen$.next(true);
    }

    this.userProfileOpen$.next(false);
  }

  closeSlideout() {
    this.slideoutOpen$.next(false);
  }

  slideoutComponent(): Observable<unknown> {
    return this.slideoutComponent$ as Observable<unknown>;
  }

  slideoutOpen(): Observable<boolean> {
    return this.slideoutOpen$ as Observable<boolean>;
  }

  toggleUserProfile() {
    this.userProfileOpen$.next(!this.userProfileOpen$.value);
    this.slideoutOpen$.next(false);
  }

  userProfileOpen(): Observable<boolean> {
    return this.userProfileOpen$ as Observable<boolean>;
  }

  closeUserProfile() {
    this.userProfileOpen$.next(false);
  }
}
