import { TabLink, TabsComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DefinitionsSlideoutComponent, UserProfileComponent } from '@cmi/shared/components';
import { SlideoutService } from '@cmi/shared/services';
import {
  AppState,
  NAV_COMPANIES,
  NAV_DASHBOARDS,
  NAV_QUARTERS,
  selectAppCompanyLogo,
  selectAppNavLocation,
  selectAppNavRoot
} from '@cmi/store/app';
import {
  selectUserPermissionAdmin,
  selectUserPermissionClient,
  selectUserPermissionInternal
} from '@cmi/store/user';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';
import { UserProfile } from 'wre-authlib';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterLink, UserProfileComponent, TabsComponent],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  adminUser$!: Observable<boolean>;
  clientUser$!: Observable<boolean>;
  hasPermission$!: Observable<boolean>;
  clientLogo$!: Observable<string>;
  navLocation$!: Observable<string>;
  adminTabs$!: Observable<TabLink[]>;
  clientTabs$!: Observable<TabLink[]>;

  constructor(
    private store: Store<UserProfile | AppState>,
    private slideoutService: SlideoutService
  ) {}

  ngOnInit() {
    this.adminUser$ = this.store.select(selectUserPermissionAdmin);
    this.clientUser$ = this.store.select(selectUserPermissionClient);
    this.hasPermission$ = combineLatest([
      this.adminUser$,
      this.store.select(selectUserPermissionInternal),
      this.clientUser$
    ]).pipe(map(([admin, internal, client]) => admin || internal || client));

    this.clientLogo$ = this.store.select(selectAppCompanyLogo);
    this.navLocation$ = this.store.select(selectAppNavLocation);

    this.adminTabs$ = combineLatest([this.navLocation$, this.store.select(selectAppNavRoot)]).pipe(
      map(
        ([navLocation, navRoot]) =>
          [
            {
              text: NAV_QUARTERS,
              routerLink: navLocation === NAV_QUARTERS && navRoot ? undefined : ['/admin/quarters']
            },
            {
              text: NAV_COMPANIES,
              routerLink:
                navLocation === NAV_COMPANIES && navRoot ? undefined : ['/admin/companies']
            }
          ] as TabLink[]
      )
    );
    this.clientTabs$ = combineLatest([this.navLocation$, this.store.select(selectAppNavRoot)]).pipe(
      map(
        ([navLocation, navRoot]) =>
          [
            {
              text: NAV_DASHBOARDS,
              routerLink: navLocation === NAV_DASHBOARDS && navRoot ? undefined : ['/dashboard']
            }
          ] as TabLink[]
      )
    );
  }

  toggleSlideout(event: Event) {
    this.slideoutService.toggleSlideoutComponent(DefinitionsSlideoutComponent);

    event.stopPropagation();
  }
}
