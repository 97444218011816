import { ToastComponent, ToastMessage } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { selectToastMessages, toastRemove } from '@cmi/store/toast';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, ToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  toastMessages$!: Observable<ToastMessage[]>;

  constructor(private store: Store<ToastMessage[]>) {}

  ngOnInit() {
    this.toastMessages$ = this.store.select(selectToastMessages);
  }

  onRemoveToast(toastMessage: ToastMessage) {
    this.store.dispatch(toastRemove({ toastMessage }));
  }
}
