import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  GridOptions,
  ColDef,
  ColTypeDef,
  RowClassParams,
  CellKeyDownEvent,
  DomLayoutType,
  FullWidthCellKeyDownEvent
} from 'ag-grid-community';

@Component({
  selector: 'app-ag-grid',
  standalone: true,
  imports: [CommonModule, AgGridAngular],
  templateUrl: './ag-grid.component.html',
  styleUrls: ['./ag-grid.component.scss']
})
export class AgGridComponent<T> implements OnInit {
  @Input() columnDefs!: ColDef<T>[];
  @Input() columnTypes!: {
    [key: string]: ColTypeDef<T>;
  };
  @Input() getRowClass?: (params: RowClassParams) => string | undefined;
  @Input() rowData!: T[];
  @Input() domLayout: DomLayoutType = 'normal';

  gridOptions: GridOptions<T> = {};

  ngOnInit() {
    this.gridOptions = {
      columnDefs: this.columnDefs,
      columnTypes: this.columnTypes,
      defaultColDef: {
        resizable: false,
        sortable: false,
        suppressMovable: true
      },
      suppressHeaderFocus: true,
      suppressRowHoverHighlight: true,
      enableCellTextSelection: true
    } as GridOptions<T>;
  }

  onKeydown(event: CellKeyDownEvent<T> | FullWidthCellKeyDownEvent<T>) {
    if ((event?.event as KeyboardEvent)?.key === 'Enter') {
      (
        (event.event?.target as HTMLElement).querySelector('.re-focus-keydown') as HTMLElement
      )?.click();
    }
  }
}
