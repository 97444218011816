import { BusyComponent, ButtonComponent, CheckboxComponent, ModalComponent } from '@ajgre/toolkit';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule
} from '@angular/forms';
import { LocalRadiosComponent } from '@cmi/shared/components';
import { LobConfig, SubLobForm, SubLobsForm } from '@cmi/shared/models';
import { LOCAL_GLOBAL_INTERNATIONAL } from '@cmi/store/app';
import {
  DASHBOARD_FILTER_LOBS_MODAL,
  dashboardFilterLobsModalSave,
  DashboardState,
  selectDashboardLobConfigs,
  selectDashboardSelectedLobs
} from '@cmi/store/dashboard';
import { ModalState, selectModalState } from '@cmi/store/modal';
import { processRemove, selectProcessBusy } from '@cmi/store/process';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, Observable, Subscription, take } from 'rxjs';

@Component({
  selector: 'app-dashboard-filter-lobs-modal',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalComponent,
    BusyComponent,
    LocalRadiosComponent,
    CheckboxComponent,
    ButtonComponent
  ],
  templateUrl: './dashboard-filter-lobs-modal.component.html',
  styleUrl: './dashboard-filter-lobs-modal.component.scss'
})
export class DashboardFilterLobsModalComponent implements OnInit, OnDestroy {
  busy$!: Observable<boolean>;

  formGroup = new FormGroup({
    lobs: new FormArray<FormGroup<SubLobsForm>>([])
  });
  lobConfigs = [] as LobConfig[];
  local = LOCAL_GLOBAL_INTERNATIONAL;
  message = 'Content Loading...';

  selectedLobs?: number[] = undefined;
  originalValues = '';
  changed = false;

  private subscription = new Subscription();

  DASHBOARD_FILTER_LOBS_MODAL = DASHBOARD_FILTER_LOBS_MODAL;

  constructor(
    private store: Store<DashboardState>,
    private modal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.busy$ = this.store.select(selectProcessBusy(DASHBOARD_FILTER_LOBS_MODAL));

    this.updateValues();
    this.buildFormArray();

    this.store
      .select(selectModalState(DASHBOARD_FILTER_LOBS_MODAL))
      .pipe(
        filter((modalState) => modalState === ModalState.Closing),
        take(1)
      )
      .subscribe(() => {
        this.modal.close();
      });
  }

  lobs(): FormArray {
    return this.formGroup.get('lobs') as FormArray;
  }

  sublobs(lob: AbstractControl): FormArray {
    return lob.get('sublobs') as FormArray;
  }

  private updateValues() {
    this.subscription.add(
      this.formGroup.valueChanges.subscribe((values) => {
        this.selectedLobs = [];
        let allSelected = true;

        this.lobConfigs.forEach((lobConfig, i) => {
          lobConfig.subLobConfigs.forEach((subLobConfig, j) => {
            const lob = values.lobs ? values.lobs[i] : undefined;

            if (lob && lob.sublobs ? lob.sublobs[j].sublob : false) {
              this.selectedLobs?.push(subLobConfig.subLobId);
            } else {
              allSelected = false;
            }
          });
        });

        if (allSelected) {
          this.selectedLobs = undefined;
        }

        this.changed = this.originalValues !== JSON.stringify(values);
      })
    );
  }

  private buildFormArray() {
    this.store
      .select(selectDashboardLobConfigs)
      .pipe(take(1))
      .subscribe((lobConfigs) => {
        this.lobConfigs = lobConfigs;

        lobConfigs.forEach((labConfig) => {
          this.formGroup.controls.lobs.push(
            new FormGroup({
              sublobs: new FormArray(
                labConfig.subLobConfigs.map(
                  () =>
                    new FormGroup({
                      sublob: new FormControl(false)
                    } as SubLobForm)
                )
              )
            })
          );
        });

        //
        // Once the above is built the pre-selected LOBs selector can be subscribed to.
        //
        // Note: the selected values won't become available until busy is complete.
        //
        this.subscription.add(
          this.store.select(selectDashboardSelectedLobs).subscribe((selectedLobs) => {
            const selectAll = !selectedLobs;

            this.lobConfigs.forEach((lobConfig, i) => {
              lobConfig.subLobConfigs.forEach((subLobConfig, j) => {
                this.formGroup.controls.lobs.controls[i].controls.sublobs.controls[
                  j
                ].controls.sublob.setValue(
                  selectAll ||
                    selectedLobs.some((selectedLob) => subLobConfig.subLobId === selectedLob)
                );
              });
            });

            this.originalValues = JSON.stringify(this.formGroup.value);
            this.changed = false;
          })
        );
      });
  }

  onReset() {
    this.lobConfigs.forEach((lobConfig, i) => {
      lobConfig.subLobConfigs.forEach((_, j) => {
        this.formGroup.controls.lobs.controls[i].controls.sublobs.controls[
          j
        ].controls.sublob.setValue(true);
      });
    });
  }

  onCancel() {
    this.modal.close();
  }

  onSave() {
    this.message = 'Content Saving...';

    this.store.dispatch(dashboardFilterLobsModalSave({ selectedLobs: this.selectedLobs }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    this.store.dispatch(processRemove({ key: DASHBOARD_FILTER_LOBS_MODAL }));
  }
}
