import { LobConfig, Local, SubLobConfig } from '@cmi/shared/models';
import {
  APP_FOOTER_HEIGHT,
  APP_HEADER_HEIGHT,
  LOCAL_GLOBAL_INTERNATIONAL,
  LOCAL_US,
  selectAppHeight
} from '@cmi/store/app';
import {
  DASHBOARD_FEATURE_KEY,
  DASHBOARD_GRID_HEADER_HEIGHT,
  DASHBOARD_GRID_PADDING_HEIGHT,
  DASHBOARD_MIN_ROWS_TO_SHOW,
  DASHBOARD_Q_ON_Q,
  DASHBOARD_ROW_HEIGHT,
  DASHBOARD_TIME_SERIES_Q_ON_Q,
  DASHBOARD_TIME_SERIES_Y_ON_Y,
  DASHBOARD_TIMELINE_PERIOD,
  DASHBOARD_Y_ON_Y,
  DashboardState,
  GridSummary,
  GridTimeSeries
} from '@cmi/store/dashboard';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectDashboardState = createFeatureSelector<DashboardState>(DASHBOARD_FEATURE_KEY);

export const selectDashboardPeriods = createSelector(
  selectDashboardState,
  (state) => state.periods
);
export const selectDashboardExecutiveSummary = createSelector(
  selectDashboardState,
  (state) => state.executiveSummary
);
export const selectDashboardExecutiveSummaryOpen = createSelector(
  selectDashboardState,
  (state) => state.executiveSummaryOpen
);
export const selectDashboardSelectedDashboard = createSelector(
  selectDashboardState,
  (state) => state.selectedDashboard
);
export const selectDashboardSelectedPeriod = createSelector(
  selectDashboardState,
  (state) => state.selectedPeriod
);
export const selectDashboardSelectedPeriodFrom = createSelector(
  selectDashboardState,
  (state) => state.selectedPeriodFrom
);
export const selectDashboardSelectedPeriodTo = createSelector(
  selectDashboardState,
  (state) => state.selectedPeriodTo
);
export const selectDashboardLabel = createSelector(selectDashboardState, (state) => {
  if (
    state.selectedDashboard === DASHBOARD_Y_ON_Y ||
    state.selectedDashboard === DASHBOARD_Q_ON_Q
  ) {
    const period = (state.periods.find((p) => p.periodId === state.selectedPeriod) ?? { label: '' })
      .label;

    return period ? `${state.selectedDashboard}: ${period}` : state.selectedDashboard;
  } else if (
    state.selectedDashboard === DASHBOARD_TIME_SERIES_Y_ON_Y ||
    state.selectedDashboard === DASHBOARD_TIME_SERIES_Q_ON_Q
  ) {
    const periodFrom = (
      state.periods.find((p) => p.periodId === state.selectedPeriodFrom) ?? { label: '' }
    ).label;
    const periodTo = (
      state.periods.find((p) => p.periodId === state.selectedPeriodTo) ?? { label: '' }
    ).label;

    return periodFrom && periodTo
      ? `${state.selectedDashboard}: ${periodFrom} - ${periodTo}`
      : state.selectedDashboard;
  }

  return '';
});
export const selectDashboardSelectedLocal = createSelector(
  selectDashboardState,
  (state) => state.selectedLocal
);
export const selectDashboardUrl = createSelector(
  selectDashboardState,
  (state) =>
    `dashboard?dashboardType=${state.selectedDashboard}&local=${state.selectedLocal}${
      state.selectedDashboard === DASHBOARD_Y_ON_Y || state.selectedDashboard === DASHBOARD_Q_ON_Q
        ? `&period=${state.selectedPeriod}`
        : `&periodFrom=${state.selectedPeriodFrom}&periodTo=${state.selectedPeriodTo}`
    }`
);

//
// Filter LOB modal selectors
//
export const selectDashboardLobs = createSelector(selectDashboardState, (state) => state.lobs);
export const selectDashboardLobConfigs = createSelector(selectDashboardState, (state) =>
  state.lobs.reduce((lobConfigs, lob) => {
    const subLobConfig = {
      subLobId: lob.subLobId,
      config: {
        id: `sublob${lob.subLobId}`,
        label: lob.subLobName
      }
    } as SubLobConfig;

    const lobConfig = lobConfigs.find((lobConfig) => lobConfig.lobId === lob.lobId);

    if (lobConfig) {
      lobConfig.subLobConfigs.push(subLobConfig);
    } else {
      lobConfigs.push({
        lobId: lob.lobId,
        lobName: lob.lobName,
        local: lob.local === Local.Global ? LOCAL_GLOBAL_INTERNATIONAL : LOCAL_US,
        subLobConfigs: [subLobConfig]
      } as LobConfig);
    }

    return lobConfigs;
  }, [] as LobConfig[])
);
export const selectDashboardSelectedLobs = createSelector(
  selectDashboardState,
  (state) => state.selectedLobs
);

//
// Grid selectors
//
const selectDashboardYonYGridData = createSelector(
  selectDashboardState,
  (state) => state.yonyGridData
);
const selectDashboardQonQGridData = createSelector(
  selectDashboardState,
  (state) => state.qonqGridData
);

export const selectDashboardSummaryRowData = createSelector(
  selectDashboardSelectedLobs,
  selectDashboardSelectedDashboard,
  selectDashboardSelectedLocal,
  selectDashboardYonYGridData,
  selectDashboardQonQGridData,
  (selectedLobs, selectedDashboard, selectedLocal, yonyGridData, qonqGridData) =>
    (selectedDashboard === DASHBOARD_Y_ON_Y ? yonyGridData : qonqGridData)
      .filter(
        (kpi) =>
          kpi.local === (selectedLocal === LOCAL_GLOBAL_INTERNATIONAL ? Local.Global : Local.US)
      )
      .filter((kpi) => !selectedLobs || selectedLobs.some((subLobId) => kpi.subLobId === subLobId))
      .reduce((rowData, kpiSummary, index, originalRowData) => {
        if (index === 0 || originalRowData[index - 1].lobId !== kpiSummary.lobId) {
          rowData.push({ lobId: kpiSummary.lobId, lobName: kpiSummary.lobName });
        }

        rowData.push({
          lobId: kpiSummary.lobId,
          lobName: kpiSummary.lobName,
          subLobId: kpiSummary.subLobId,
          subLobName: kpiSummary.subLobName,
          pdfFilename: kpiSummary.pdfFilename,
          pptFilename: kpiSummary.pptFilename,
          houseView: {
            label: kpiSummary.houseView,
            section: kpiSummary.houseViewSection
          },
          pricing: {
            kpi: kpiSummary.pricing,
            section: kpiSummary.pricingSection
          },
          capacity: {
            kpi: kpiSummary.capacity,
            section: kpiSummary.capacitySection
          },
          competition: {
            kpi: kpiSummary.competition,
            section: kpiSummary.competitionSection
          },
          reinsuranceCosts: {
            kpi: kpiSummary.reinsuranceCosts,
            section: kpiSummary.reinsuranceCostsSection
          },
          profitablilty: {
            kpi: kpiSummary.profitablilty,
            section: kpiSummary.profitabliltySection
          },
          claimsDynamics: {
            kpi: kpiSummary.claimsDynamics,
            section: kpiSummary.claimsDynamicsSection
          },
          tAndCs: {
            kpi: kpiSummary.tAndCs,
            section: kpiSummary.tAndCsSection
          }
        });

        return rowData;
      }, [] as GridSummary[])
);

const selectDashboardTimeSeriesYonYGridData = createSelector(
  selectDashboardState,
  (state) => state.timeSeriesYonYGridData
);
const selectDashboardTimeSeriesQonQGridData = createSelector(
  selectDashboardState,
  (state) => state.timeSeriesQonQGridData
);

export const selectDashboardTimeSeriesRowData = createSelector(
  selectDashboardPeriods,
  selectDashboardSelectedLobs,
  selectDashboardSelectedDashboard,
  selectDashboardSelectedPeriodFrom,
  selectDashboardSelectedPeriodTo,
  selectDashboardSelectedLocal,
  selectDashboardTimeSeriesYonYGridData,
  selectDashboardTimeSeriesQonQGridData,
  (
    periods,
    selectedLobs,
    selectedDashboard,
    selectedPeriodFrom,
    selectedPeriodTo,
    selectedLocal,
    timeSeriesYonYGridData,
    timeSeriesQonQGridData
  ) => {
    const periodFrom = periods.find((p) => p.periodId === selectedPeriodFrom);
    const periodTo = periods.find((p) => p.periodId === selectedPeriodTo);

    return (
      selectedDashboard === DASHBOARD_TIME_SERIES_Y_ON_Y
        ? timeSeriesYonYGridData
        : timeSeriesQonQGridData
    )
      .filter(
        (kpi) =>
          kpi.local === (selectedLocal === LOCAL_GLOBAL_INTERNATIONAL ? Local.Global : Local.US)
      )
      .filter((kpi) => !selectedLobs || selectedLobs.some((subLobId) => kpi.subLobId === subLobId))
      .filter((kpi) => {
        const kpiPeriod = periods.find((p) => p.periodId === kpi.periodId);

        return (
          kpiPeriod &&
          (!periodFrom || kpiPeriod.dateTo >= periodFrom.dateTo) &&
          (!periodTo || kpiPeriod.dateTo <= periodTo.dateTo)
        );
      })
      .reduce((rowData, kpiTimeSeries, index, originalRowData) => {
        if (index === 0 || originalRowData[index - 1].lobId !== kpiTimeSeries.lobId) {
          rowData.push({ lobId: kpiTimeSeries.lobId, lobName: kpiTimeSeries.lobName });
        }

        if (index === 0 || originalRowData[index - 1].subLobId !== kpiTimeSeries.subLobId) {
          rowData.push({
            lobId: kpiTimeSeries.lobId,
            lobName: kpiTimeSeries.lobName,
            subLobId: kpiTimeSeries.subLobId,
            subLobName: kpiTimeSeries.subLobName
          });
          rowData.push({
            lobId: kpiTimeSeries.lobId,
            lobName: kpiTimeSeries.lobName,
            subLobId: kpiTimeSeries.subLobId,
            subLobName: kpiTimeSeries.subLobName,
            periodId: DASHBOARD_TIMELINE_PERIOD,
            periodLabel: DASHBOARD_TIMELINE_PERIOD
          });
        }

        const kpiPeriod = periods.find((p) => p.periodId === kpiTimeSeries.periodId);

        rowData.push({
          periodId: kpiPeriod?.periodId,
          periodLabel: kpiPeriod?.label,
          lobId: kpiTimeSeries.lobId,
          lobName: kpiTimeSeries.lobName,
          subLobId: kpiTimeSeries.subLobId,
          subLobName: kpiTimeSeries.subLobName,
          pdfFilename: kpiTimeSeries.pdfFilename,
          pptFilename: kpiTimeSeries.pptFilename,
          houseView: {
            label: kpiTimeSeries.houseView,
            section: kpiTimeSeries.houseViewSection
          },
          pricing: {
            kpi: kpiTimeSeries.pricing,
            section: kpiTimeSeries.pricingSection
          },
          capacity: {
            kpi: kpiTimeSeries.capacity,
            section: kpiTimeSeries.capacitySection
          },
          competition: {
            kpi: kpiTimeSeries.competition,
            section: kpiTimeSeries.competitionSection
          },
          reinsuranceCosts: {
            kpi: kpiTimeSeries.reinsuranceCosts,
            section: kpiTimeSeries.reinsuranceCostsSection
          },
          profitablilty: {
            kpi: kpiTimeSeries.profitablilty,
            section: kpiTimeSeries.profitabliltySection
          },
          claimsDynamics: {
            kpi: kpiTimeSeries.claimsDynamics,
            section: kpiTimeSeries.claimsDynamicsSection
          },
          tAndCs: {
            kpi: kpiTimeSeries.tAndCs,
            section: kpiTimeSeries.tAndCsSection
          }
        });

        return rowData;
      }, [] as GridTimeSeries[]);
  }
);

export const selectDashboardSummaryGridHeight = createSelector(
  selectAppHeight,
  selectDashboardSummaryRowData,
  (height, rowData) => gridHeight(height, rowData.length)
);

export const selectDashboardTimeSeriesGridHeight = createSelector(
  selectAppHeight,
  selectDashboardTimeSeriesRowData,
  (height, rowData) => gridHeight(height, rowData.length)
);

function gridHeight(height: number, rows: number): string {
  const gridHeight = Math.max(
    Math.min(
      height -
        APP_HEADER_HEIGHT -
        APP_FOOTER_HEIGHT -
        DASHBOARD_GRID_HEADER_HEIGHT -
        DASHBOARD_GRID_PADDING_HEIGHT,
      (rows + 1) * DASHBOARD_ROW_HEIGHT + 1
    ),
    DASHBOARD_ROW_HEIGHT * Math.min(rows + 1, DASHBOARD_MIN_ROWS_TO_SHOW) + 1
  );

  return `${Math.max(gridHeight, DASHBOARD_ROW_HEIGHT * DASHBOARD_MIN_ROWS_TO_SHOW + 1)}px`;
}

//
// KPI Narrative selectors
//
export const selectDashboardOpenKpiNarrativeSlideout = createSelector(
  selectDashboardState,
  (state) => state.kpiNarrativeSlideoutData
);

export const selectDashboardKpiNarrativeSelectedData = createSelector(
  selectDashboardState,
  (state) =>
    state.selectedKpiNarrative?.filter((kpiNarrative) =>
      comparePeriods(kpiNarrative.periodId, state.selectedPeriodFrom, state.selectedPeriodTo)
    ) ?? []
);

function comparePeriods(
  kpiPeriodId: string,
  stateFromPeriodId: string,
  stateToPeriodId: string
): boolean {
  const kpi = getQuarterDate(kpiPeriodId);
  const stateFrom = getQuarterDate(stateFromPeriodId);
  const stateTo = getQuarterDate(stateToPeriodId);

  return kpi >= stateFrom && kpi <= stateTo;
}
function getQuarterDate(periodId: string) {
  const year = periodId.substring(0, 4);
  const quarter = periodId.substring(4, 6);
  let month = 0;
  let day = 0;

  switch (quarter) {
    case 'Q1':
      month = 3;
      day = 31;
      break;
    case 'Q2':
      month = 6;
      day = 30;
      break;
    case 'Q3':
      month = 9;
      day = 31;
      break;
    case 'Q4':
      month = 12;
      day = 31;
      break;
  }

  return new Date(+year, month, day);
}
