import { KpiCellComponent } from '@cmi/shared/components';
import { MetricsLob } from '@cmi/store/app';
import { ColDef, RowClassParams } from 'ag-grid-community';

export const metricsColumnDefs: ColDef<MetricsLob>[] = [
  {
    headerName: 'KPI',
    width: 80,
    cellRendererSelector: (params) =>
      params.data?.kpi !== undefined ? { component: KpiCellComponent } : undefined,
    valueGetter: (params) => params.data?.kpi ?? params.data?.lob,
    cellClass: (params) => (params.data?.kpi !== undefined ? 're-sub-lob' : 're-lob'),
    colSpan: (params) => (params.data?.kpi !== undefined ? 1 : 2),
    suppressNavigable: true
  },
  {
    field: 'description',
    width: 350,
    suppressNavigable: true
  }
];

export const metricsGetRowClass = (params: RowClassParams) =>
  params.data.kpi !== undefined ? '' : 're-header-row';
