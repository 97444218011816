import { ButtonComponent } from '@ajgre/toolkit';
import { CommonModule, Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { HISTORY_TOKEN } from '@cmi/app';
import {
  DASHBOARD_Q_ON_Q,
  DASHBOARD_TIME_SERIES_Q_ON_Q,
  DASHBOARD_TIME_SERIES_Y_ON_Y,
  DASHBOARD_Y_ON_Y
} from '@cmi/store/dashboard';
import {
  selectUserPermissionAdmin,
  selectUserPermissionClient,
  selectUserPermissionInternal,
  UserState
} from '@cmi/store/user';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, of, take } from 'rxjs';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [CommonModule, RouterLink, ButtonComponent],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss'
})
export class ErrorComponent implements OnInit {
  hasHistory$!: Observable<boolean>;
  hasPermission$!: Observable<boolean>;

  yonyParams = { dashboardType: DASHBOARD_Y_ON_Y };
  qonqParams = { dashboardType: DASHBOARD_Q_ON_Q };
  timeSeriesYonYParams = { dashboardType: DASHBOARD_TIME_SERIES_Y_ON_Y };
  timeSeriesQonQParams = { dashboardType: DASHBOARD_TIME_SERIES_Q_ON_Q };

  errorCode!: number;
  headingTitle!: string;
  headingDescription!: string;

  headings: { [index: number]: { title: string; description: string } } = {
    404: {
      title: 'Page not found',
      description: 'The page you are looking for has either moved or does not exist.'
    },
    403: {
      title: 'Unauthorised access',
      description:
        'You do not have access to view this page, please contact an admin to request access.'
    }
  };

  constructor(
    private store: Store<UserState>,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    @Inject(HISTORY_TOKEN) private history: History
  ) {}

  ngOnInit() {
    this.hasHistory$ = of(this.history.length > 1);
    this.hasPermission$ = combineLatest([
      this.store.select(selectUserPermissionAdmin),
      this.store.select(selectUserPermissionInternal),
      this.store.select(selectUserPermissionClient)
    ]).pipe(map(([admin, internal, client]) => admin || internal || client));

    this.activatedRoute.data.pipe(take(1)).subscribe((data) => {
      this.errorCode = data['errorCode'] ?? 404;
      this.headingTitle = this.headings[this.errorCode]?.title;
      this.headingDescription = this.headings[this.errorCode]?.description;
    });
  }

  navigateBack() {
    this.location.back();
  }
}
